<template>
  <div class="grid formgrid p-fluid">
    <Loader v-model="loading" />
      <div class="col-12">
        <Card>
            <template #title>
                Historial de Ventas
            </template>
            <template #content>
                <div class="grid formgrid p-fluid">
                    <div class="col-10 field">
                        <label for="">Fecha</label>
                        <Calendar @date-select="dateSelected" v-model="date" dateFormat="dd/mm/yy" :touchUI="true"/>
                        <br>
                    </div>
                    <div class="col" style="margin-top:1.75rem;">
                        <Button @click="exportXLS" icon="pi pi-file-excel" label="Descargar XLS" class="p-button-success" />
                    </div>
                </div>
                <TabView @tab-change="onTabChange" :activeIndex="tab">
                    <TabPanel v-for="paymentType in paymentTypes" :key="paymentType.id" :header="paymentType.name">
                        <div class="grid formgrid p-fluid">
                            <div class="col-4">
                                <Listbox 
                                class="orderitems"
                                listStyle="max-height:25rem"
                                style="width: 100%"
                                v-model="selectedTicket" 
                                :options="getTicket(paymentType)" 
                                optionLabel="total" 
                                emptyMessage="No se encontraron tickets">
                                    <template #option="slotProps">
                                        <div class="grid p-fluid">
                                            <div class="col-8">
                                                <strong>Ticket #{{slotProps.option.id_number}}</strong>
                                            </div>
                                            <div class="col-4">
                                                {{formatCurrency(slotProps.option.total)}}
                                            </div>
                                            <div class="col-12">
                                                {{formatDescription(slotProps.option.details)}}
                                            </div>
                                        </div>
                                    </template>
                                </Listbox>
                            </div>
                            <div v-if="selectedTicket" class="col-8">
                                <Panel :header="('Ticket #' + selectedTicket.id_number)" :toggleable="true">
                                    <p><strong>Tipo de Orden</strong>: {{selectedTicket.id_service_type == "1" ? "MESA" : ""}}</p>
                                    <p v-if="(selectedTicket.id_service_type == 1 && selectedTicket.table_name)"><strong>Mesa</strong>: {{selectedTicket.table_name}}</p>
                                    <p v-if="(selectedTicket.id_service_type == 1 && selectedTicket.id_waiter)"><strong>Mesero</strong>: {{selectedTicket.waiter_name}}</p>
                                    <p v-if="selectedTicket.id_customer"><strong>Cliente</strong>: {{selectedTicket.customer_name}}</p>
                                    <p><strong>Fecha</strong>: {{selectedTicket.date}}</p>
                                    <div>
                                        <BasicDatatable 
                                        :headers="headers" 
                                        :rows="selectedTicket.details"
                                        :headerVisible="true" :rowsFilter="false" />
                                    </div>
                                </Panel>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
                <BasicDatatable v-show="false"
                :rows="tickets"
                :headers="ticketHeaders"
                ref="ticketGrid"></BasicDatatable>
            </template>
        </Card>
      </div>
    </div>
</template>

<script>
import BasicDatatable from '../../components/general/BasicDatatable.vue'
import Loader from '../../components/general/Loader.vue'
import { basicData } from '../../data/basicData'
import FormMixin from '../../mixins/form.js'
import { ticket } from '../../models/ticket'
import formatCurrency from '../../utilities/formatCurrency'
import { HeaderGrid } from '../../utilities/General'

   
export default {
    components: {Loader, BasicDatatable},
    mixins: [FormMixin],
    data() {
        return  {
            loading: false,
            date: new Date(),
            tickets: [],
            paymentTypes: [],
            selectedTicket: null,
            ticketHeaders: [
                new HeaderGrid('Ticket','id_number'),
                new HeaderGrid('Fecha','realDate', { type: 'date-time'}),
                new HeaderGrid('SubTotal','subtotal', { type: 'currency', function: "SUM"}),
                new HeaderGrid('IVA','iva', { type: 'currency', function: 'SUM'}),
                new HeaderGrid('Envio','shipping', { type: 'currency', function: "SUM"}),
                new HeaderGrid('Total','total', { type: 'currency', function: "SUM"}),
                new HeaderGrid('Identificador','identifier'),
                new HeaderGrid('Cliente','customer_name'),
            ],
            headers: [
                new HeaderGrid('Cantidad', 'quantity'),
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Precio', 'unit_price',  { function: 'SUM', type: 'currency' }),
                new HeaderGrid('Subtotal', 'subtotal', { function: 'SUM', type: 'currency' }),
                new HeaderGrid('Total', 'total', { function: 'SUM', type: 'currency' }),

            ]
        }
    },
    methods: {
        exportXLS() {
            this.$refs.ticketGrid.exportXLS();
        },
        getTicket(paymentType) {
            return this.tickets.filter(x => x.name_payment_type == paymentType.name) ?? [];
        }, 
        async dateSelected(val) {
            this.loading = true;
            try {
                this.tickets = await new ticket(this.session).history(val);
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        formatCurrency: formatCurrency,
        formatDescription(details) {
            details = details ?? [];
            let description = "";
            details.forEach(detail => {
                description += detail.name + " x " + detail.quantity + ", ";
            });
            description = description.slice(0, -2);
            description = description.length > 55 ? 
            description.slice(0, 55) + "..." 
            : description;
            return description;
        }
    },
    async mounted() {
        this.loading = true;
        try {

            this.paymentTypes = await new basicData(this.session).getPaymentTypes();
            this.tickets = await new ticket(this.session).history(this.date);
        } catch (error) {
            this.showError(error);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style>

.p-listbox-list-wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.p-listbox-list-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

/* Handle */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
</style>